import React from 'react';
// @mui
import { Grid, Divider } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RSelect from '../../../componentes/forms/inputs/RSelect';
import RCheck from '../../../componentes/forms/inputs/RCheck';
import { insertSpacesAtPosition } from "../../../auxiliares/funciones";
import RLabel from "../../../componentes/forms/inputs/RLabel";

// ----------------------------------------------------------------------

export default function EmpForm({ showLoader, actualizaCampo, empresa, provincias, asesorias }) {   

  React.useEffect(() => {
    showLoader(false); 
    // eslint-disable-next-line
  },[]);

    const desmontaIban = code =>{
        if(code.length === 24){
            let iban = insertSpacesAtPosition(code,14)
            iban = insertSpacesAtPosition(iban,12)
            iban = insertSpacesAtPosition(iban,8)
            iban = insertSpacesAtPosition(iban,4)
           return iban
        }
        return ''
    }

    const [ibanD, setIbanD] = React.useState(empresa.cuentaBancaria !== '' ? desmontaIban(empresa.cuentaBancaria) : '');
    const [key, setKey] = React.useState(Math.random());

    const compruebaCambios = (e) =>{
        if(e.target.name === 'cuentaBancaria'){
            setIbanD(desmontaIban(e.target.value));
            setKey(Math.random())
        }
        actualizaCampo(e)
    }

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RSelect name="idAsesoria" label="Asesoría*" actualizaCampo={actualizaCampo} value={empresa.idAsesoria} md={6}
          values={asesorias} searchable={1}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nombre" label="Razón Social*" actualizaCampo={actualizaCampo} value={empresa.nombre} md={6} />
        <RTextField type="text" name="cif" label="CIF*" actualizaCampo={actualizaCampo} value={empresa.cif} md={2} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="email" label="Email*" actualizaCampo={actualizaCampo} value={empresa.email} md={5} />
        <RTextField type="text" name="telefono" label="Teléfono*" actualizaCampo={actualizaCampo} value={empresa.telefono} md={2} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="representanteLegal" label="Representante Legal" actualizaCampo={actualizaCampo} value={empresa.representanteLegal} md={6} />
        <RTextField type="text" name="cifRepresentante" label="CIF Representante" actualizaCampo={actualizaCampo} value={empresa.cifRepresentante} md={2} />
        </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="conceptoRepresentante" label="Concepto Representante" actualizaCampo={actualizaCampo} value={empresa.conceptoRepresentante} md={4} />
        <RSelect name="sexoRepresentante" label="Sexo" actualizaCampo={actualizaCampo} value={empresa.sexoRepresentante} md={2}
          values={[{id:1,nombre:'Hombre'},{id:0,nombre:'Mujer'}]}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="direccion" label="Direccion" actualizaCampo={actualizaCampo} value={empresa.direccion} md={5} />
        <RTextField type="text" name="cp" label="C.P." actualizaCampo={actualizaCampo} value={empresa.cp} md={1} />
        <RTextField type="text" name="localidad" label="Localidad" actualizaCampo={actualizaCampo} value={empresa.localidad} md={2} />
        <RSelect name="idProvincia" label="Provincia" actualizaCampo={actualizaCampo} value={empresa.idProvincia} md={3}
          values={provincias} searchable={1}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="direccionCentroTrabajo" label="Direccion del Centro de Trabajo" actualizaCampo={actualizaCampo} value={empresa.direccionCentroTrabajo} md={9} />
      </Grid>
      <Divider style={{ margin: '15px', marginBottom: '30px'}} />
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RCheck name="centrosComunidad" label="¿Dispone de centros de trabajo en más de una Com. Aut.?" actualizaCampo={actualizaCampo} value={empresa.centrosComunidad} md={6} />
        <RTextField type="text" name="cnae" label="CNAE" actualizaCampo={actualizaCampo} value={empresa.cnae} md={3} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="cuentaCotizacion" label="Cta. de Cotización" actualizaCampo={actualizaCampo} value={empresa.cuentaCotizacion} md={3} />
        <RTextField type="text" name="cuentaBancaria" label="Cuenta Bancaria" actualizaCampo={compruebaCambios} value={empresa.cuentaBancaria} md={3} />
        <RLabel key={key} md={3} label={ibanD}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="bic" label="SWIFT BIC" actualizaCampo={actualizaCampo} value={empresa.bic} md={3} />
        <RTextField type="number" name="numTrabajadores" label="Trabajadores en Plantilla" actualizaCampo={actualizaCampo} value={empresa.numTrabajadores} md={2} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RCheck name="reduccionCuotaMinima" label="Cuota mínima 149,06" actualizaCampo={actualizaCampo} value={empresa.reduccionCuotaMinima} md={3} />
        <RSelect name="bonificacionTutor" label="Bonificación Tutor" actualizaCampo={actualizaCampo} value={empresa.bonificacionTutor} md={2}
          values={[{id:0,nombre:'Selecciona una Opción'},{id:1,nombre:'60'},{id:2,nombre:'80'}]}/>
      </Grid>
    </form>
  );
}
