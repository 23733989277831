import React from 'react';
// @mui
import { Grid, Divider } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RDateField from '../../../componentes/forms/inputs/RDateField';
import RSelect from '../../../componentes/forms/inputs/RSelect';
import RCheck from '../../../componentes/forms/inputs/RCheck';
import RTextArea from '../../../componentes/forms/inputs/RTextArea';
import RLabel from '../../../componentes/forms/inputs/RLabel';

import { isEmpty, roundHalf, numberToTime } from '../../../auxiliares/funciones';

// ----------------------------------------------------------------------

export default function ContForm({ showLoader, actualizaCampo, contrato, comerciales, asesorias, empresas, trabajadores, ocupaciones, centrosformacion,convenios, provincias, famProfesionales, tutores }) {
    const compruebaCambioHoras = (campo,valor) =>{
        let h = horas;
        if(campo === 'horasLaborales'){
            h = calculaHoras(valor);
        }else if(campo === 'hl1'){
            const hl = roundHalf(valor/0.65);
            h = calculaHoras(hl);
            actualizaCampo({target:{name:'horasLaborales',value:hl}}) 
        }else if(campo === 'hf1'){
            const hl = roundHalf(valor/0.35);
            h = calculaHoras(hl);
            actualizaCampo({target:{name:'horasLaborales',value:hl}}) 
        }
        setHoras(h)
        setKey(Math.random())
    }

    const calculaHoras = (valor) =>{
        const h = {hf:'',hl1:0,hf1:0,hl2:0,hf2:0,hl1f:'',hf1f:'',hl2f:'',hf2f:''};
        h.hf = numberToTime(valor)
        h.hl1 = roundHalf(valor*0.65);
        h.hf1 = roundHalf(valor*0.35);
        h.hl2 = roundHalf(valor*0.85);
        h.hf2 = roundHalf(valor*0.15);
        h.hl1f = numberToTime(h.hl1);
        h.hf1f = numberToTime(h.hf1);
        h.hl2f = numberToTime(h.hl2);
        h.hf2f = numberToTime(h.hf2);
        return h;
    }
  
  React.useEffect(() => {
    showLoader(false);
    // eslint-disable-next-line
  },[]);

  const muestrasHorasFormConv = (horas,porc) => horas === '' ? '' : `${roundHalf(horas*porc)} h. Form. ${porc === 0.35 ? '1er año' : '2do año'}`;

  const compruebaHorasConvenio = (idConvenio,tipo) =>{
    const conv = convenios.find(a => a.id === idConvenio);
    // let horas = (conv.horas / 12) * (contrato.duracion>12 ? 12 : contrato);
    const horas =  conv.horas
    /* if(contrato.horasLaborales !== 40){
        horas = (horas * contrato.horasLaborales) / 40;
    } */
    if(tipo === 1){
        return muestrasHorasFormConv(horas,0.35)
    }
    return muestrasHorasFormConv(horas,0.15)
  }

const calculaHorasForm = (duracion,convenio) =>{
    const conv = convenios.find(a => a.id === convenio);
    let horas = ((conv.horas / 12) * (duracion>12 ? 12 : duracion)) * 0.35;
    if(duracion>12){
        const d = duracion - 12;
        horas += ((conv.horas / 12) * d ) * 0.15;
    }
    return roundHalf(horas);
  }



  const [horas, setHoras] = React.useState(calculaHoras(contrato.horasLaborales));
  const [key, setKey] = React.useState(Math.random());
  const [horasForm, setHorasForm] = React.useState(contrato.idConvenio !== 0 ? calculaHorasForm(contrato.duracion,contrato.idConvenio) : 0.0);
  const [horasConv, setHorasConv] = React.useState(contrato.idConvenio !== 0 ? compruebaHorasConvenio(contrato.idConvenio,1) : '');
  const [horasConv2, setHorasConv2] = React.useState(contrato.idConvenio !== 0 ? compruebaHorasConvenio(contrato.idConvenio,2) : '');
  const [asesorias2, setAsesorias] = React.useState(contrato.idComercial !== 0 ? asesorias.filter(a => a.idComercial === contrato.idComercial) : [{id:0,nombre:'Ninguno'}]);
  const [empresas2, setEmpresas] = React.useState(contrato.idAsesoria !== 0 ? empresas.filter(a => a.idAsesoria === contrato.idAsesoria) : [{id:0,nombre:'Ninguno'}]);
  const [trabajadores2, setTrabajadores] = React.useState(contrato.idEmpresa !== 0 ? trabajadores.filter(a => a.idEmpresa === contrato.idEmpresa) : [{id:0,nombre:'Ninguno'}]);
  const [ocupaciones2, setOcupaciones] = React.useState(contrato.idFamiliaProfesional !== 0 ? ocupaciones.filter(a => a.idFamilia === contrato.idFamiliaProfesional) : [{id:0,nombre:'Ninguno'}]);

  const compruebaCambios = (e) =>{
    if(e.target.name === 'idComercial'){
        const listado = asesorias.filter(a => a.idComercial === e.target.value);
        setAsesorias(isEmpty(listado) ? [{id:0,nombre:'Ninguno'}] : listado);
    }
    if(e.target.name === 'idAsesoria'){
        const listado = empresas.filter(a => a.idAsesoria === e.target.value);
        setEmpresas(isEmpty(listado) ? [{id:0,nombre:'Ninguno'}] : listado);
    }
    if(e.target.name === 'idEmpresa'){
        const listado = trabajadores.filter(a => a.idEmpresa === e.target.value);
        setTrabajadores(isEmpty(listado) ? [{id:0,nombre:'Ninguno'}] : listado);
    }
    if(e.target.name === 'tipoContrato'){
        const val = e.target.value !== 2 ? '0' : '40';
        actualizaCampo({target:{value:val,name:'horasLaborales'}})
    }
    if(e.target.name === 'idConvenio'){
        setHorasConv(compruebaHorasConvenio(e.target.value,1));
        setHorasConv2(compruebaHorasConvenio(e.target.value,2));
        setHorasForm(calculaHorasForm(contrato.duracion,e.target.value))
    }
    if(e.target.name === 'idFamiliaProfesional'){
        const listado = ocupaciones.filter(a => a.idFamilia === e.target.value);
        setOcupaciones(isEmpty(listado) ? [{id:0,nombre:'Ninguno'}] : listado);
    }
    if(e.target.name === 'horasLaborales' || e.target.name === 'hl1' || e.target.name === 'hf1'){
        compruebaCambioHoras(e.target.name,e.target.value);
    }
    actualizaCampo(e)    
    setKey(Math.random())
  }


  return (
    <form style={{ width: '100%'}} key={key}>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RSelect name="idComercial" label="Comercial*" actualizaCampo={compruebaCambios} value={contrato.idComercial} md={6}
            values={comerciales} searchable={1}/>
            <RSelect name="idAsesoria" label="Asesoría*" actualizaCampo={compruebaCambios} value={contrato.idAsesoria} md={6}
            values={asesorias2} searchable={1}/>
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RSelect name="idEmpresa" label="Empresa*" actualizaCampo={compruebaCambios} value={contrato.idEmpresa} md={6}
            values={empresas2} searchable={1}/>
            <RSelect name="idTrabajador" label="Trabajador*" actualizaCampo={compruebaCambios} value={contrato.idTrabajador} md={6}
            values={trabajadores2} searchable={1}/>
        </Grid>
        <Divider style={{ margin: '15px', marginBottom: '30px'}} />
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RSelect name="idCentroFormacion" label="Centro de Formación*" actualizaCampo={actualizaCampo} value={contrato.idCentroFormacion} md={6}
                     values={centrosformacion}/>
            <RSelect name="idTutor" label="Tutor del Centro" actualizaCampo={actualizaCampo} value={contrato.idTutor} md={4}
                     values={tutores}/>
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RCheck name="bonificacionTotal" label="Bonif. Total" actualizaCampo={compruebaCambios} value={contrato.bonificacionTotal} md={2} />
            <RCheck name="cantidadMinima" label="C. Mínima" actualizaCampo={compruebaCambios} value={contrato.cantidadMinima} md={2} />
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RDateField name="fechaInicio" label="Fecha Inicio*" actualizaCampo={actualizaCampo} value={contrato.fechaInicio} md={2} />
            <RTextField type="number" name="duracion" label="Duración (Meses)*" actualizaCampo={actualizaCampo} value={contrato.duracion} md={2} />
            <RLabel md={1} label={`${horasForm} h. Form`}/>
            <RDateField name="fechaFin" label="Fecha Fin*" actualizaCampo={actualizaCampo} value={contrato.fechaFin} md={2} />
            <RTextField name="fechaFinOriginal" label="Fecha Fin Original" actualizaCampo={actualizaCampo} value={contrato.fechaFinOriginal} md={2} inputProps={{ readOnly: true, }} />
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RSelect name="idFamiliaProfesional" label="Fam. Profesional*" actualizaCampo={compruebaCambios} value={contrato.idFamiliaProfesional} md={6}
            values={famProfesionales} searchable={1}/>
            <RSelect name="idOcupacion" label="Ocupación*" actualizaCampo={actualizaCampo} value={contrato.idOcupacion} md={6}
            values={ocupaciones2} searchable={1}/>
        </Grid>   
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RSelect name="tipoContrato" label="Tipo de Contrato" actualizaCampo={compruebaCambios} value={contrato.tipoContrato} md={2}
            values={[{id:0,nombre:'Selecciona uno'},{id:1,nombre:'Jornada Parcial'},{id:2,nombre:'Jornada Completa'}]}/>
            <RTextField type="text" name="horarioGeneral" label="Horario General" actualizaCampo={actualizaCampo} value={contrato.horarioGeneral} md={2} />            
            <RTextField type="text" name="horarioTrabajo" label="Horario de Trabajo" actualizaCampo={actualizaCampo} value={contrato.horarioTrabajo} md={2} />            
            <RTextField type="text" name="horarioFormacion" label="Horario de Form." actualizaCampo={actualizaCampo} value={contrato.horarioFormacion} md={2} />         
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RTextField type="text" name="horarioGeneral2" label="Horario General 2do Año" actualizaCampo={actualizaCampo} value={contrato.horarioGeneral2} md={2} />
            <RTextField type="text" name="horarioTrabajo2" label="Horario de Trabajo 2do Año" actualizaCampo={actualizaCampo} value={contrato.horarioTrabajo2} md={2} />
            <RTextField type="text" name="horarioFormacion2" label="Horario de Form. 2do Año" actualizaCampo={actualizaCampo} value={contrato.horarioFormacion2} md={2} />
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RTextField type="number" name="horasLaborales" label="Horas Laborales" actualizaCampo={compruebaCambios} focusout={1} value={contrato.horasLaborales} small={horas.hf} md={2} />   
            <RTextField type="number" name="hl1" label="H. Lab. 1er año" actualizaCampo={compruebaCambios} focusout={1} value={horas.hl1} md={2} small={horas.hl1f}/>   
            <RTextField type="number" name="hf1" label="H. Form. 1er año" actualizaCampo={compruebaCambios} focusout={1} value={horas.hf1} md={2} small={horas.hf1f} />   
            <RTextField type="number" name="hl2" label="H. Lab 2do año" value={horas.hl2} md={2} inputProps={{ readOnly: true, }} small={horas.hl2f}/>   
            <RTextField type="number" name="hf2" label="H. Form. 2do año" value={horas.hf2} md={2} inputProps={{ readOnly: true, }} small={horas.hf2f}/>     
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RSelect name="idConvenio" label="Convenio Colectivo*" actualizaCampo={compruebaCambios} value={contrato.idConvenio} md={10}
            values={convenios} searchable={1} position={'left'}/>
            <RLabel md={1} label={horasConv}/>
            <RLabel md={1} label={horasConv2}/>
        </Grid>
        <Divider style={{ margin: '15px', marginBottom: '30px'}} />
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RTextField type="text" name="direccionTrabajo" label="Dirección Puesto de Trabajo" actualizaCampo={actualizaCampo} value={contrato.direccionTrabajo} md={6} />
            <RSelect name="idProvinciaTrabajo" label="Provincia Puesto de Trabajo" actualizaCampo={actualizaCampo} value={contrato.idProvinciaTrabajo} md={3}
            values={provincias} searchable={1}/>
            <RTextField type="text" name="localidadTrabajo" label="Localidad Puesto de Trabajo" actualizaCampo={actualizaCampo} value={contrato.localidadTrabajo} md={3} /> 
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RTextField type="text" name="nombreTutorLaboral" label="Tutor Laboral 1" actualizaCampo={actualizaCampo} value={contrato.nombreTutorLaboral} md={4} />
            <RTextField type="text" name="nifTutorLaboral" label="NIF Tutor 1" actualizaCampo={actualizaCampo} value={contrato.nifTutorLaboral} md={2} /> 
            <RTextField type="text" name="mailTutorLaboral" label="Mail Tutor 1" actualizaCampo={actualizaCampo} value={contrato.mailTutorLaboral} md={4} />
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RTextField type="text" name="nombreTutorLaboral2" label="Tutor Laboral 2" actualizaCampo={actualizaCampo} value={contrato.nombreTutorLaboral2} md={4} />
            <RTextField type="text" name="nifTutorLaboral2" label="NIF Tutor 2" actualizaCampo={actualizaCampo} value={contrato.nifTutorLaboral2} md={2} />
            <RTextField type="text" name="mailTutorLaboral2" label="Mail Tutor 2" actualizaCampo={actualizaCampo} value={contrato.mailTutorLaboral2} md={4} />
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RTextArea name="observaciones" label="Observaciones" actualizaCampo={actualizaCampo} value={contrato.observaciones} md={6} />
        </Grid>
    </form>
  );
}
