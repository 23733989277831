import React from 'react';
// @mui
import { Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
// components
import Iconify from '../../../componentes/Iconify';

import {buildBody, getUniqueKey, isEmpty, workWithLocalStorage} from '../../../auxiliares/funciones';
import { getBonificacion } from '../../../auxiliares/clases/contratos';
import CONFIG from '../../../CONFIG.json';
import RTabla from '../../../componentes/tabla';

// ----------------------------------------------------------------------

export default function BoniForm({ showLoader, contrato }) {   

  const [boni, setBoni] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const usuario = workWithLocalStorage('get','usuario',null);

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){         
            setBoni(await getBonificacion(contrato.id));
            setCargado(true);
            setKey(getUniqueKey())
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

  const CalculaBonificacion = () =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true); 
    const body = { id : contrato.id, usuario: usuario.id};

    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}contrato/bonificacion/calcular`,{
            method: 'POST',
            body: bodyJson,
            headers: {'Content-Type': 'application/json'}
    })
    .then(res => res.json())
    .then((result) => {
        if (result.error === 0) {   
            setCargado(false);    
            Swal.fire({
              title: 'Bonificación calculada!',
              buttons: [{label: 'Ok'}]
            })      
        }else{
          Swal.fire({
            title: result.errorMessage,
            buttons: [{label: 'Ok'}]
          })
        }
        showLoader(false); 
    })
}

  /* ***************** TABLA ************************** */
  const campos = ['mes','fechaInicio','fechaFin','dias','horas','importe','estado'];    
  const cabecera = ['Mes','Fecha Inicio','Fecha Fin','Días','Horas','Importe','Estado'];

  return (
    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={12}>
          <Button variant="contained" color="warning" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:file-text-outline" />}
          onClick={()=>CalculaBonificacion()}> Calcular Bonificación </Button>
        </Grid>        
        {!isEmpty(boni) &&
        <Grid item md={12} key={key}>
            <RTabla lista={boni} campos={campos} cabecera={cabecera} acciones={[]}/>
        </Grid>
        }
    </Grid>
  );
}
